import React, { useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Typed from 'typed.js';

gsap.registerPlugin(ScrollTrigger);

function Index() {
    const Home = React.createRef();
    const Initials = React.createRef();
    const titleBlock = React.createRef();
    const bgVideo = React.createRef();
    const LinkedIn = React.createRef();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        let initialAnim = gsap.fromTo(Initials.current, { scale: 0.2, opacity: 0 }, { scale: 1, opacity: 1, duration: 2, ease: "elastic" });
            initialAnim.delay(1)

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: 'main',
                start: "top",
                // end: "+=" + window.innerHeight * 2,
                end: 'bottom',
                // pin: true,
                // pinSpacing: false,
                scrub: true,
                markers: false,
                //onUpdate: () => { console.log('Update') }
            }
        });

        tl.fromTo(bgVideo.current, { opacity: 0.05 }, { opacity: 0.2 }, 0)
        tl.fromTo(Initials.current, { scale: 1 }, { scale: 0 }, 0)
        tl.fromTo('.scroll-icon', { autoAlpha: 1 }, { autoAlpha: 0 }, 0)

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: titleBlock.current,
                start: "top top+=50%",
                end: "+=50%",
                scrub: true,
                markers: false,
                //onUpdate: () => { console.log('Update') }
            }
        });

        tl2.fromTo(titleBlock.current, { opacity: 0 }, { opacity: 1 }, 0)
        tl2.fromTo(titleBlock.current.querySelector('.inner'), { x: 0, y: 0 }, { x: (window.innerWidth >= 768 ? 75 : 0), y: (window.innerWidth >= 768 ? 0 : -75) }, 1)
        tl2.fromTo(LinkedIn.current, { autoAlpha: 0 }, { autoAlpha: 1 }, 1)


        // Typed
        new Typed('.title-block .type p', {
            strings: ['<span>Senior</span> developer', '<span>Front-end</span> developer', '<span>WordPress</span> developer', '<span>React</span> developer', '<span>UX</span>-designer'],
            typeSpeed: 50,
            loop: true,
            backDelay: 500,
            shuffle: true,
        });

    });

    return (
        <div id="home" ref={Home}>
            <div className="background-video" ref={bgVideo}><video src={process.env.PUBLIC_URL + 'bg-video.mp4'} muted autoPlay loop></video></div>
            <div className="initials" ref={Initials}>
                N<span>L</span>
            </div>
            <div className="scroll-icon">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 90 112.5"><circle cx="45" cy="13" r="3"/><path d="M45,0.5c-8.284,0-15,6.717-15,15.002v24.996C30,48.783,36.716,55.5,45,55.5s15-6.717,15-15.002V15.502  C60,7.217,53.284,0.5,45,0.5z M58,40.498C58,47.667,52.168,53.5,45,53.5c-7.168,0-13-5.833-13-13.002V15.502  C32,8.333,37.832,2.5,45,2.5c7.168,0,13,5.833,13,13.002V40.498z"/><path d="M58.293,59.793L45,73.086L31.707,59.793c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14,14  C44.488,75.402,44.744,75.5,45,75.5s0.512-0.098,0.707-0.293l14-14c0.391-0.391,0.391-1.023,0-1.414S58.684,59.402,58.293,59.793z"/><path d="M58.293,73.793L45,87.086L31.707,73.793c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14,14  C44.488,89.402,44.744,89.5,45,89.5s0.512-0.098,0.707-0.293l14-14c0.391-0.391,0.391-1.023,0-1.414S58.684,73.402,58.293,73.793z"/></svg>
            </div>
            <div className="title-block" ref={titleBlock}>
                <div className="inner">
                    <h1>Niels <span>Lust</span></h1>
                    <p className="employed-at">Werkzaam bij <a href="https://go2people.nl" target="_blank" rel="noreferrer">Go2People</a></p>
                    <div className="type"><p></p></div>

                    <a className="linked-in" ref={LinkedIn} href="https://www.linkedin.com/in/niels-lust-b2141318/" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2389" viewBox="0 5 1036 990"><path d="M0 120c0-33.334 11.667-60.834 35-82.5C58.333 15.833 88.667 5 126 5c36.667 0 66.333 10.666 89 32 23.333 22 35 50.666 35 86 0 32-11.333 58.666-34 80-23.333 22-54 33-92 33h-1c-36.667 0-66.333-11-89-33S0 153.333 0 120zm13 875V327h222v668H13zm345 0h222V622c0-23.334 2.667-41.334 8-54 9.333-22.667 23.5-41.834 42.5-57.5 19-15.667 42.833-23.5 71.5-23.5 74.667 0 112 50.333 112 151v357h222V612c0-98.667-23.333-173.5-70-224.5S857.667 311 781 311c-86 0-153 37-201 111v2h-1l1-2v-95H358c1.333 21.333 2 87.666 2 199 0 111.333-.667 267.666-2 469z"/></svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Index;

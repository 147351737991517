import Home from './components/Home/index';
import React from 'react';

function App() {

  return (
    <div className="App">
        <main>
            <Home />
        </main>
    </div>
  );
}

export default App;
